<template>
  <div v-if="_.isPlainObject($store.state.profile) && $store.state.patient" class="records">
    <v-data-table
      v-model="selectedRecords"
      height="calc(100vh - 155px)"
      show-select
      fixed-header
      :footer-props="{ showCurrentPage: true, showFirstLastPage: true }"
      :items="records"
      :items-per-page="-1"
      :headers="recordsDataTableHeaders"
    >
      <template #top>
        <v-toolbar flat dense>
          <v-row :style="{ flex: `0 0 ${$vuetify.breakpoint.mobile ? (48 + 4) * 2 : 240}px` }">
            <v-tooltip v-if="selectedRecords.length > 0" bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  :rounded="!$vuetify.breakpoint.mobile"
                  :text="!$vuetify.breakpoint.mobile"
                  :outlined="!$vuetify.breakpoint.mobile"
                  :icon="$vuetify.breakpoint.mobile"
                  class="mr-1"
                  color="success"
                  v-bind="attrs"
                  @click.stop="editSelectedRecordsDialogIsOpen = true"
                  v-on="on"
                >
                  <v-icon :left="!$vuetify.breakpoint.mobile">mdi-square-edit-outline</v-icon>
                  <template v-if="!$vuetify.breakpoint.mobile">編輯</template>
                </v-btn>
              </template>
              <span>編輯</span>
            </v-tooltip>
            <v-tooltip v-if="selectedRecords.length > 0" bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  :rounded="!$vuetify.breakpoint.mobile"
                  :text="!$vuetify.breakpoint.mobile"
                  :outlined="!$vuetify.breakpoint.mobile"
                  :icon="$vuetify.breakpoint.mobile"
                  class="mr-1"
                  color="error"
                  v-bind="attrs"
                  @click.stop="deleteSelectedRecordsDialogIsOpen = true"
                  v-on="on"
                >
                  <v-icon :left="!$vuetify.breakpoint.mobile">mdi-delete</v-icon>
                  <template v-if="!$vuetify.breakpoint.mobile">刪除</template>
                </v-btn>
              </template>
              <span>刪除</span>
            </v-tooltip>
            <v-spacer />
          </v-row>
          <v-spacer />
          <v-toolbar-title>紀錄</v-toolbar-title>
          <v-spacer />
          <v-row :style="{ flex: `0 0 ${$vuetify.breakpoint.mobile ? (48 + 4) * 2 : 240}px` }">
            <v-spacer />
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-if="records.length > 0"
                  :rounded="!$vuetify.breakpoint.mobile"
                  :text="!$vuetify.breakpoint.mobile"
                  :outlined="!$vuetify.breakpoint.mobile"
                  :icon="$vuetify.breakpoint.mobile"
                  class="ml-1"
                  color="warning"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="exportRecordsDialogIsOpen = true"
                >
                  <v-icon :left="!$vuetify.breakpoint.mobile">mdi-export-variant</v-icon>
                  <template v-if="!$vuetify.breakpoint.mobile">匯出</template>
                </v-btn>
              </template>
              <span>匯出</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  :rounded="!$vuetify.breakpoint.mobile"
                  :text="!$vuetify.breakpoint.mobile"
                  :outlined="!$vuetify.breakpoint.mobile"
                  :icon="$vuetify.breakpoint.mobile"
                  class="ml-1"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="addRecordsDialogIsOpen = true"
                >
                  <v-icon :left="!$vuetify.breakpoint.mobile">mdi-plus</v-icon>
                  <template v-if="!$vuetify.breakpoint.mobile">新增</template>
                </v-btn>
              </template>
              <span>新增</span>
            </v-tooltip>
          </v-row>
        </v-toolbar>
      </template>
      <template #[`item.measuredAt`]="{ item }">
        {{ item.measuredAtString }}
      </template>
    </v-data-table>
    <add-records-dialog v-model="addRecordsDialogIsOpen" />
    <delete-selected-records-dialog
      v-model="deleteSelectedRecordsDialogIsOpen"
      :records="selectedRecords"
    />
    <edit-selected-records-dialog v-model="editSelectedRecordsDialogIsOpen" />
    <export-records-dialog v-model="exportRecordsDialogIsOpen" :records="records" />
  </div>
</template>

<script>
import Vue from 'vue';

import AddRecordsDialog from '@/components/Records/AddRecordsDialog';
import DeleteSelectedRecordsDialog from '@/components/Records/DeleteSelectedRecordsDialog';
import EditSelectedRecordsDialog from '@/components/Records/EditSelectedRecordsDialog';
import ExportRecordsDialog from '@/components/Records/ExportRecordsDialog';
import { Date } from '@/extensions';

export default Vue.extend({
  name: 'Record',
  components: {
    AddRecordsDialog,
    DeleteSelectedRecordsDialog,
    EditSelectedRecordsDialog,
    ExportRecordsDialog,
  },
  data: () => ({
    recordsDataTableHeaders: [
      { text: '#', value: 'number', align: 'end', sortable: false },
      { text: '量測於', value: 'measuredAt', align: 'center' },
      { text: '狀態', value: 'status', align: 'center' },
      { text: '收縮壓（mmHg）', value: 'systolicBloodPressure', align: 'end' },
      { text: '舒張壓（mmHg）', value: 'diastolicBloodPressure', align: 'end' },
      { text: '心率（bpm）', value: 'heartRate', align: 'end' },
    ],
    measuredDataStatus: {
      normal: '正常',
      manual: '手動新增',
    },
    selectedRecords: [],
    addRecordsDialogIsOpen: false,
    deleteSelectedRecordsDialogIsOpen: false,
    editSelectedRecordsDialogIsOpen: false,
    exportRecordsDialogIsOpen: false,
  }),
  computed: {
    records() {
      if (!this.$store.state.measurementRecords) return [];
      const records = Array.from({ length: this.$store.state.measurementRecords.length });
      for (const [i, record] of this.$store.state.measurementRecords.entries()) {
        records[i] = new Object();
        records[i].id = record.id;
        records[i].number = i + 1;
        const measuredAtDate = Date.withoutSeconds(record.measuredAt.toDate());
        records[i].measuredAt = measuredAtDate.getTime();
        records[i].measuredAtString = measuredAtDate.longLocalizedString;
        records[i].measuredAtIsoString = measuredAtDate.toISOString();
        records[i].status =
          this.measuredDataStatus[record.measuredData.status] ?? record.measuredData.status;
        records[i].systolicBloodPressure = record.measuredData.bloodPressure.systolic;
        records[i].diastolicBloodPressure = record.measuredData.bloodPressure.diastolic;
        records[i].heartRate = record.measuredData.heartRate;
      }
      return records;
    },
  },
  watch: {
    deleteSelectedRecordsDialogIsOpen(value) {
      if (value) return;
      const recordIds = this.records.map((record) => record.id);
      for (const recordId of this.selectedRecords.map((record) => record.id)) {
        if (!recordIds.includes(recordId)) {
          const index = this.selectedRecords.map((record) => record.id).indexOf(recordId);
          this.selectedRecords.splice(index, 1);
        }
      }
    },
  },
});
</script>
