<template>
  <v-dialog
    class="export-records-dialog"
    :value="value"
    :persistent="requesting"
    max-width="540px"
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-toolbar color="primary" dark dense>
        <v-icon left>mdi-export-variant</v-icon>
        <v-toolbar-title>匯出{{ records.length }}筆紀錄</v-toolbar-title>
        <v-spacer />
        <v-btn icon :disabled="requesting" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <v-form v-model="isFormValid">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="file.name"
                  dense
                  :hide-details="$vuetify.breakpoint.mobile"
                  label="檔案名稱"
                  :rules="[inputRules.required, inputRules.filename]"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="file.extension"
                  dense
                  hide-details
                  :items="[
                    { value: '.json', text: 'JSON' },
                    { value: '.csv', text: 'CSV' },
                  ]"
                  label="匯出格式"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="file.eol"
                  dense
                  hide-details
                  :items="[
                    { value: '\n', text: '用於Unix-like作業系統' },
                    { value: '\r\n', text: '用於Windows作業系統' },
                  ]"
                  label="檔案格式"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="file.kind"
                  dense
                  hide-details
                  :items="[
                    { value: 'normal', text: '一般' },
                    { value: 'extend', text: '擴展' },
                  ]"
                  label="匯出種類"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-container>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text :disabled="requesting" @click="close()">取消</v-btn>
        <v-btn
          color="primary"
          depressed
          :disabled="!isFormValid"
          :loading="requesting"
          @click="request()"
        >
          匯出
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';

import inputRules from '@/mixins/inputRules';

export default Vue.component(
  'export-records-dialog',
  Vue.extend({
    name: 'ExportRecordsDialog',
    mixins: [inputRules],
    props: {
      value: { type: Boolean, default: true },
      records: { type: Array, default: () => new Array() },
    },
    data: () => ({
      isFormValid: false,
      requesting: false,
      file: {
        name: 'records',
        extension: '.csv',
        eol: '\n',
        kind: 'extend',
      },
    }),
    created() {
      if (this.$store.state.patient)
        this.file.name += `-${this.$store.state.patient.profile.fullName}`;
      if (navigator.platform.startsWith('Win')) this.file.eol = '\r\n';
    },
    methods: {
      fileText(text) {
        return (this.file.eol === '\r\n' ? '\ufeff' : '') + text + this.file.eol;
      },
      generateJsonDataUrl(data) {
        return (
          'data:application/json;charset=utf-8,' +
          encodeURIComponent(this.fileText(JSON.stringify(data)))
        );
      },
      generateCsvDataUrl(data) {
        const rows = new Array();
        rows.push(Object.keys(data[0]).join());
        for (const d of data) rows.push(Object.values(d).join());
        return (
          'data:text/csv;charset=utf-8,' +
          encodeURIComponent(this.fileText(rows.join(this.file.eol)))
        );
      },
      prepareData() {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const column =
          this.file.kind === 'normal'
            ? (r) => ({
                measuredAt: r.measuredAtIsoString,
                status: r.status,
                systolicBloodPressure: r.systolicBloodPressure,
                diastolicBloodPressure: r.diastolicBloodPressure,
                heartRate: r.heartRate,
              })
            : (r) => ({
                '量測日期': r.measuredAtIsoString,
                '時區': timeZone,
                '收縮壓(mmHg)': r.systolicBloodPressure,
                '舒張壓(mmHg)': r.diastolicBloodPressure,
                '脈搏(bpm)': r.heartRate,
                '檢測到不規則脈波': '',
                '不規則脈波檢測次數(次數)': '',
                '身體晃動檢測': '',
                '壓脈帶緊度檢查': '',
                '量測姿勢正確符號': '',
                '室温(°C)': '',
                '量測模式': '',
                '型號': '',
              });
        const data = new Array();
        for (const record of this.records) data.push(column(record));
        return data;
      },
      request() {
        this.requesting = true;
        let filename = this.file.name.trim();
        if (!filename.endsWith(this.file.extension)) filename += this.file.extension;
        const element = document.createElement('a');
        const data = this.prepareData();
        let dataUrlGenerator = null;
        switch (this.file.extension) {
          case '.json':
            dataUrlGenerator = this.generateJsonDataUrl;
            break;
          case '.csv':
            dataUrlGenerator = this.generateCsvDataUrl;
            break;
        }
        element.setAttribute('href', dataUrlGenerator(data));
        element.setAttribute('download', filename);
        // FIXME: not working on Firefox Android
        element.click();
        this.close();
        this.requesting = false;
      },
      close() {
        this.$emit('input', false);
      },
    },
  }),
);
</script>
