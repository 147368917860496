<template>
  <v-dialog
    class="edit-selected-records-dialog"
    :value="value"
    persistent
    max-width="720px"
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-btn color="warning" block text @click="$emit('input', false)">Not Implemented</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';

export default Vue.component(
  'edit-selected-records-dialog',
  Vue.extend({
    name: 'EditSelectedRecordsDialog',
    props: {
      value: { type: Boolean, default: true },
    },
  }),
);
</script>
