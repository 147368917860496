var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm._.isPlainObject(_vm.$store.state.profile) && _vm.$store.state.patient)?_c('div',{staticClass:"records"},[_c('v-data-table',{attrs:{"height":"calc(100vh - 155px)","show-select":"","fixed-header":"","footer-props":{ showCurrentPage: true, showFirstLastPage: true },"items":_vm.records,"items-per-page":-1,"headers":_vm.recordsDataTableHeaders},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-row',{style:({ flex: ("0 0 " + (_vm.$vuetify.breakpoint.mobile ? (48 + 4) * 2 : 240) + "px") })},[(_vm.selectedRecords.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"rounded":!_vm.$vuetify.breakpoint.mobile,"text":!_vm.$vuetify.breakpoint.mobile,"outlined":!_vm.$vuetify.breakpoint.mobile,"icon":_vm.$vuetify.breakpoint.mobile,"color":"success"},on:{"click":function($event){$event.stopPropagation();_vm.editSelectedRecordsDialogIsOpen = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":!_vm.$vuetify.breakpoint.mobile}},[_vm._v("mdi-square-edit-outline")]),(!_vm.$vuetify.breakpoint.mobile)?[_vm._v("編輯")]:_vm._e()],2)]}}],null,false,191119708)},[_c('span',[_vm._v("編輯")])]):_vm._e(),(_vm.selectedRecords.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"rounded":!_vm.$vuetify.breakpoint.mobile,"text":!_vm.$vuetify.breakpoint.mobile,"outlined":!_vm.$vuetify.breakpoint.mobile,"icon":_vm.$vuetify.breakpoint.mobile,"color":"error"},on:{"click":function($event){$event.stopPropagation();_vm.deleteSelectedRecordsDialogIsOpen = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":!_vm.$vuetify.breakpoint.mobile}},[_vm._v("mdi-delete")]),(!_vm.$vuetify.breakpoint.mobile)?[_vm._v("刪除")]:_vm._e()],2)]}}],null,false,427303087)},[_c('span',[_vm._v("刪除")])]):_vm._e(),_c('v-spacer')],1),_c('v-spacer'),_c('v-toolbar-title',[_vm._v("紀錄")]),_c('v-spacer'),_c('v-row',{style:({ flex: ("0 0 " + (_vm.$vuetify.breakpoint.mobile ? (48 + 4) * 2 : 240) + "px") })},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.records.length > 0)?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"rounded":!_vm.$vuetify.breakpoint.mobile,"text":!_vm.$vuetify.breakpoint.mobile,"outlined":!_vm.$vuetify.breakpoint.mobile,"icon":_vm.$vuetify.breakpoint.mobile,"color":"warning"},on:{"click":function($event){$event.stopPropagation();_vm.exportRecordsDialogIsOpen = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":!_vm.$vuetify.breakpoint.mobile}},[_vm._v("mdi-export-variant")]),(!_vm.$vuetify.breakpoint.mobile)?[_vm._v("匯出")]:_vm._e()],2):_vm._e()]}}],null,false,176419478)},[_c('span',[_vm._v("匯出")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"rounded":!_vm.$vuetify.breakpoint.mobile,"text":!_vm.$vuetify.breakpoint.mobile,"outlined":!_vm.$vuetify.breakpoint.mobile,"icon":_vm.$vuetify.breakpoint.mobile,"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.addRecordsDialogIsOpen = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":!_vm.$vuetify.breakpoint.mobile}},[_vm._v("mdi-plus")]),(!_vm.$vuetify.breakpoint.mobile)?[_vm._v("新增")]:_vm._e()],2)]}}],null,false,3444439351)},[_c('span',[_vm._v("新增")])])],1)],1)]},proxy:true},{key:"item.measuredAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.measuredAtString)+" ")]}}],null,true),model:{value:(_vm.selectedRecords),callback:function ($$v) {_vm.selectedRecords=$$v},expression:"selectedRecords"}}),_c('add-records-dialog',{model:{value:(_vm.addRecordsDialogIsOpen),callback:function ($$v) {_vm.addRecordsDialogIsOpen=$$v},expression:"addRecordsDialogIsOpen"}}),_c('delete-selected-records-dialog',{attrs:{"records":_vm.selectedRecords},model:{value:(_vm.deleteSelectedRecordsDialogIsOpen),callback:function ($$v) {_vm.deleteSelectedRecordsDialogIsOpen=$$v},expression:"deleteSelectedRecordsDialogIsOpen"}}),_c('edit-selected-records-dialog',{model:{value:(_vm.editSelectedRecordsDialogIsOpen),callback:function ($$v) {_vm.editSelectedRecordsDialogIsOpen=$$v},expression:"editSelectedRecordsDialogIsOpen"}}),_c('export-records-dialog',{attrs:{"records":_vm.records},model:{value:(_vm.exportRecordsDialogIsOpen),callback:function ($$v) {_vm.exportRecordsDialogIsOpen=$$v},expression:"exportRecordsDialogIsOpen"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }