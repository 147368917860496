<template>
  <v-dialog
    class="add-records-dialog"
    :value="value"
    :persistent="records.length > 0 || requesting"
    max-width="1080px"
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-toolbar color="primary" dark dense>
        <v-icon left>mdi-plus</v-icon>
        <v-toolbar-title>新增{{ records.length }}筆紀錄</v-toolbar-title>
        <v-spacer />
        <v-btn icon :disabled="requesting" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <v-form v-model="isFormValid" :disabled="requesting">
          <v-container v-for="(record, i) in records" :key="`records-${i}`" fluid>
            <v-row no-gutters>
              <v-col cols="auto" class="pr-3" style="padding-top: 2px">
                <v-btn icon color="error" :disabled="requesting" @click="records.splice(i, 1)">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-dialog
                      ref="pickRecordDateTimeDialog"
                      v-model="record.PickDateTimeDialogIsOpen"
                      width="290px"
                      persistent
                      :return-value.sync="record.dateTimeString"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="record.localizedDateString"
                          dense
                          label="量測於"
                          readonly
                          v-bind="attrs"
                          :rules="[inputRules.required]"
                          v-on="on"
                        />
                      </template>
                      <v-card>
                        <v-date-time-picker
                          v-model="record.dateTimeString"
                          color="primary"
                          dark
                          full-width
                          scrollable
                        />
                        <v-divider />
                        <v-card-actions>
                          <v-spacer />
                          <v-btn color="error" text @click="pickRecordDateTimeDialogCancel(record)">
                            取消
                          </v-btn>
                          <v-btn
                            color="primary"
                            depressed
                            :disabled="!record.dateTimeString"
                            @click="
                              pickRecordDateTimeDialogOk(record, $refs.pickRecordDateTimeDialog[i])
                            "
                          >
                            確定
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      v-model.number="record.systolicBloodPressure"
                      dense
                      label="收縮壓"
                      suffix="mmHg"
                      type="number"
                      :rules="[inputRules.required, inputRules.integerRange(20, 300).within]"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      v-model.number="record.diastolicBloodPressure"
                      dense
                      label="舒張壓"
                      suffix="mmHg"
                      type="number"
                      :rules="[inputRules.required, inputRules.integerRange(20, 300).within]"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      v-model.number="record.heartRate"
                      dense
                      label="心率"
                      suffix="bpm"
                      type="number"
                      :rules="[inputRules.required, inputRules.integerRange(20, 150).within]"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider />
          </v-container>
          <v-container>
            <v-row>
              <v-col>
                <v-btn color="primary" block text :disabled="requesting" @click="addNewRecord()">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-container>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text :disabled="requesting" @click="close()">
          <!-- TODO: close / cancel UX, remove persistent and add warning -->
          取消
        </v-btn>
        <v-btn
          color="primary"
          depressed
          :loading="requesting"
          :disabled="!isFormValid"
          @click="request()"
        >
          完成
        </v-btn>
      </v-card-actions>
    </v-card>
    <message-snackbar
      v-model="$data.$_mixin_messageSnackbar_isShowing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
  </v-dialog>
</template>

<script>
import Vue from 'vue';

import * as dateTimeString from '@/utils/dateTimeString';
import VDateTimePicker from '@/components/Vuetify/VDateTimePicker';
import inputRules from '@/mixins/inputRules';
import messageSnackbar, { MessageSnackbarType } from '@/mixins/messageSnackbar';
import { Date } from '@/extensions';
import { firestore } from '@/firebase';

export default Vue.component(
  'add-records-dialog',
  Vue.extend({
    name: 'AddRecordsDialog',
    components: {
      VDateTimePicker,
    },
    mixins: [messageSnackbar, inputRules],
    props: {
      value: { type: Boolean, default: true },
    },
    data: () => ({
      recordTemplate: {
        dateTimeString: null,
        systolicBloodPressure: null,
        diastolicBloodPressure: null,
        heartRate: null,
        PickDateTimeDialogIsOpen: false,
        lastDateTimeString: null,
        localizedDateString: null,
      },

      requesting: false,
      isFormValid: false,
      records: [],
    }),
    watch: {
      value(value) {
        if (value && this.records.length === 0) this.addNewRecord();
      },
    },
    methods: {
      addNewRecord() {
        const newRecord = { ...this.recordTemplate };
        const nowDate = Date.withoutSeconds();
        newRecord.dateTimeString = dateTimeString.fromDate(nowDate);
        newRecord.lastDateTimeString = newRecord.dateTimeString;
        newRecord.localizedDateString = nowDate.shortLocalizedString;
        this.records.push(newRecord);
      },
      pickRecordDateTimeDialogOk(record, ref) {
        record.lastDateTimeString = record.dateTimeString;
        record.localizedDateString = dateTimeString.toDate(
          record.dateTimeString,
        ).shortLocalizedString;
        ref.save(record.dateTimeString);
      },
      pickRecordDateTimeDialogCancel(record) {
        record.dateTimeString = record.lastDateTimeString;
        record.localizedDateString = dateTimeString.toDate(
          record.lastDateTimeString,
        ).shortLocalizedString;
        record.PickDateTimeDialogIsOpen = false;
      },
      async request() {
        this.requesting = true;
        const batch = firestore.batch();
        const records = firestore
          .collection('patients')
          .doc(this.$store.state.patientId)
          .collection('measurementRecords');
        for (const record of this.records) {
          const docRef = records.doc();
          batch.set(docRef, {
            measuredAt: dateTimeString.toDate(record.dateTimeString),
            measuredData: {
              status: 'manual',
              bloodPressure: {
                systolic: record.systolicBloodPressure,
                diastolic: record.diastolicBloodPressure,
              },
              heartRate: record.heartRate,
            },
          });
        }
        await batch
          .commit()
          .then(() => this.close())
          .catch((error) =>
            this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message, '確定'),
          );
        this.requesting = false;
      },
      close() {
        this.records.splice(0, this.records.length);
        this.$emit('input', false);
      },
    },
  }),
);
</script>
