<template>
  <v-dialog
    class="delete-selected-records-dialog"
    :value="value"
    :persistent="requesting"
    max-width="540px"
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-toolbar color="error" dark dense>
        <v-icon left>mdi-delete</v-icon>
        <v-toolbar-title>刪除{{ records.length }}筆紀錄</v-toolbar-title>
        <v-spacer />
        <v-btn icon :disabled="requesting" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>確定要刪除{{ records.length === 1 ? '這筆' : '這些' }}紀錄嗎？</v-card-title>
      <v-simple-table dense fixed-header height="240px">
        <template #default>
          <thead>
            <tr>
              <th class="text-center">#</th>
              <th class="text-center">量測於</th>
              <th class="text-center">狀態</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="record in records" :key="`records-${record.id}`">
              <td class="text-center">{{ record.number }}</td>
              <td class="text-center">{{ record.measuredAtString }}</td>
              <td class="text-center">{{ record.status }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text :disabled="requesting" @click="close()">否</v-btn>
        <v-btn color="primary" depressed :loading="requesting" @click="request()">是</v-btn>
      </v-card-actions>
    </v-card>
    <message-snackbar
      v-model="$data.$_mixin_messageSnackbar_isShowing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
  </v-dialog>
</template>

<script>
import Vue from 'vue';

import messageSnackbar, { MessageSnackbarType } from '@/mixins/messageSnackbar';
import { firestore } from '@/firebase';

export default Vue.component(
  'delete-selected-records-dialog',
  Vue.extend({
    name: 'DeleteSelectedRecordsDialog',
    mixins: [messageSnackbar],
    props: {
      value: { type: Boolean, default: true },
      records: { type: Array, default: () => new Array() },
    },
    data: () => ({
      requesting: false,
    }),
    methods: {
      async request() {
        this.requesting = true;
        const batch = firestore.batch();
        const records = firestore
          .collection('patients')
          .doc(this.$store.state.patientId)
          .collection('measurementRecords');
        for (const record of this.records) batch.delete(records.doc(record.id));
        await batch
          .commit()
          .then(() => this.close())
          .catch((error) =>
            this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, error.message, '確定'),
          );
        this.requesting = false;
      },
      close() {
        this.$emit('input', false);
      },
    },
  }),
);
</script>
